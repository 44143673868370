import "./Team.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import LinkedInIcon from "../../../img/social/linkedin.svg";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";
import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";

const mdOffsets = [1, 2, 7, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];

const Team = ({ highlighted, title, teamMembers }) => {
  return (
    <section className="Team">
      <Row>
        <SectionTitle text={title} highlight={highlighted} />
        {teamMembers.map((member, i) => {
          return (
            <Col xs={12} md={4} mdOffset={mdOffsets[i]} key={i}>
              <Member {...member} className={i === 0 ? "first" : ""} />
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default Team;

const Member = ({
  businessCard,
  description,
  hoverInfos,
  name,
  role,
  image,
  className,
}) => {
  return (
    <div className={`Team-member ${className}`}>
      <div className="image-container">
        <PreviewCompatibleImage imageInfo={image} className="image" />
        <div className="reveal">{hoverInfos}</div>
      </div>
      <div className="content">
        <span className="role">{role}</span>
        <h3>{name}</h3>
        <p>{description}</p>
        <a href={businessCard} title="Voir le profil" target="blank">
          <img src={LinkedInIcon} alt="" />
        </a>
      </div>
    </div>
  );
};
