import "./Testimonials.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

import QuoteIcon from "../../../img/decoration/quote.svg";
import React from "react";

SwiperCore.use([Autoplay, Navigation]);

const Testimonials = ({ title, testimonialList }) => {
  return (
    <section className="Testimonials">
      <Row>
        <Col xs={12}>
          <h2>{title}</h2>
        </Col>
        {testimonialList && (
          <Swiper
            slidesPerView={1}
            spaceBetween={128}
            navigation
            loop={true}
            autoplay={{
              delay: 6000,
            }}
          >
            {testimonialList.map(({ author, comment }, i) => {
              return (
                <SwiperSlide key={i} className={"Testimonials-slide"}>
                  <div className="Testimonials-content">
                    <img src={QuoteIcon} alt="" />
                    <p>{comment}</p>
                  </div>
                  <div className="Testimonials-author">{author}</div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Row>
    </section>
  );
};

export default Testimonials;
