import "./AboutHero.scss";

import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const AboutHero = ({ featuredImage, title }) => {
  return (
    <section className="AboutHero">
      <div className="back">{title}</div>
      <PreviewCompatibleImage imageInfo={featuredImage} className="image" />
    </section>
  );
};

export default AboutHero;
