import AboutHero from "../components/sections/AboutHero/AboutHero.js";
import Layout from "../components/layout/Layout";
import Method from "../components/sections/Method/Method.js";
import React from "react";
import Resources from "../components/sections/Resources/Resources.js";
import ScrollAnimation from "react-animate-on-scroll";
import Services from "../components/sections/Services/Services.js";
import Story from "../components/sections/Story/Story.js";
import { StyledGrid } from "../helpers/helpers.js";
import Team from "../components/sections/Team/Team.js";
import Testimonials from "../components/sections/Testimonials/Testimonials.js";
import Us from "../components/sections/Us/Us.js";
import { graphql } from "gatsby";

export const AboutPageTemplate = ({
  method,
  resources,
  services,
  team,
  story,
  testimonials,
  us,
  featuredImage,
  title,
}) => {
  return (
    <StyledGrid>
      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <AboutHero featuredImage={featuredImage} title={title} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Us {...us} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Services {...services} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Method {...method} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Team {...team} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Story {...story} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Resources {...resources} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInBottom" duration={1.5}>
        <Testimonials {...testimonials} />
      </ScrollAnimation>
    </StyledGrid>
  );
};

const AboutPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;
  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        featuredImage={frontmatter.featuredImage}
        method={frontmatter.method}
        resources={frontmatter.resources}
        services={frontmatter.services}
        team={frontmatter.team}
        story={frontmatter.story}
        testimonials={frontmatter.testimonials}
        us={frontmatter.us}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        us {
          title
          description
          imageList {
            imageText
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
        services {
          highlighted
          title
          rightCol {
            paragraph {
              content
              title
            }
          }
          leftCol {
            paragraph {
              content
              title
            }
          }
        }
        resources {
          highlighted
          title
          paragraph
          resourceList {
            resource {
              description
              file {
                publicURL
              }
              image {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
        testimonials {
          title
          testimonialList {
            author
            comment
          }
        }
        team {
          highlighted
          title
          teamMembers {
            businessCard
            description
            hoverInfos
            name
            role
            image {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
        story {
          paragrapheOne
          paragrapheTwo
          paragrapheThree
        }
        method {
          highlighted
          title
          stepOne {
            description
            subtitle
            title
          }
          stepThree {
            description
            subtitle
            title
          }
          stepTwo {
            title
            subtitle
            description
          }
        }
      }
    }
  }
`;
