import "./Resources.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import { Swiper, SwiperSlide } from "swiper/react";

import ArrowDown from "../../../img/decoration/arrow-download.svg";
import GroupButton from "../../partials/GroupButton/GroupButton.js";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";
import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";
import { useWindowSize } from "../../../helpers/helpers.js";

// import "swiper/swiper.scss";

const Resources = ({ highlighted, title, paragraph, resourceList }) => {
  const { isMobile } = useWindowSize();
  return (
    <section className="Resources">
      <Row>
        <SectionTitle text={title} highlight={highlighted} isRight={true} />
        <Col
          xs={12}
          md={11}
          mdOffset={1}
          className="Resources-paragraph with-square"
        >
          <p>{paragraph}</p>
        </Col>
        {resourceList && !isMobile ? (
          resourceList.map((resource, i) => {
            return (
              <Col xs={12} sm={6} md={4} key={i}>
                <ResourceCard {...resource} />
              </Col>
            );
          })
        ) : (
          <Col xs={12}>
            <Swiper slidesPerView={1.25} spaceBetween={16}>
              {resourceList &&
                resourceList.map((resource, i) => {
                  return (
                    <SwiperSlide key={i} className={"Resrouces-slide"}>
                      <ResourceCard {...resource} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default Resources;

const ResourceCard = ({ resource: { image, description, file } }) => {
  return (
    <div className="Resources-card">
      <div className="image-container">
        <PreviewCompatibleImage imageInfo={image} className="image" />
        <div className="reveal">{description}</div>
      </div>
      <GroupButton
        text={"TÉLECHARGER"}
        icon={ArrowDown}
        to={file.publicURL}
        target={"blank"}
      />
    </div>
  );
};
