import "./Method.scss";
import "swiper/swiper.scss";
import "swiper/components/controller/controller.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";
import stepOneImage from "../../../img/step/step-1.svg";
import stepOneNumber from "../../../img/step/step-1-number.svg";
import stepThreeImage from "../../../img/step/step-3.svg";
import stepThreeNumber from "../../../img/step/step-3-number.svg";
import stepTwoImage from "../../../img/step/step-2.svg";
import stepTwoNumber from "../../../img/step/step-2-number.svg";
import { useWindowSize } from "../../../helpers/helpers.js";

SwiperCore.use([Navigation]);

const Method = ({ title, stepOne, stepTwo, stepThree, highlighted }) => {
  const { isMobile } = useWindowSize();
  const steps = [stepOne, stepTwo, stepThree];
  const stepNumber = [stepOneNumber, stepTwoNumber, stepThreeNumber];
  const stepImage = [stepOneImage, stepTwoImage, stepThreeImage];
  const [activeStep, setActiveStep] = useState(0);

  return (
    <section className="Method">
      <Row>
        <SectionTitle text={title} highlight={highlighted} isRight={true} />
        {!isMobile ? (
          <div className="Method-container">
            <Col xs={4} xsOffset={0} md={3} mdOffset={1}>
              {steps &&
                steps.map(({ title, subtitle }, i) => {
                  return (
                    <div
                      key={i}
                      className={`Method-step ${
                        activeStep === i ? "active" : ""
                      }`}
                      onClick={() => setActiveStep(i)}
                      role="button"
                      tabIndex={i}
                      onKeyDown={() => setActiveStep(i)}
                      aria-label={`Afficher etape ${i + 1}`}
                    >
                      <img src={stepNumber[i]} alt="" />
                      <h3>{title}</h3>
                      <p>{subtitle}</p>
                    </div>
                  );
                })}
            </Col>
            <Col xs={7} xsOffset={1} md={6} mdOffset={1}>
              {steps &&
                steps.map(({ description }, i) => {
                  return (
                    <div
                      key={i}
                      className={`Method-content ${
                        activeStep === i ? "active" : ""
                      }`}
                    >
                      <img src={stepImage[i]} alt="" />
                      <p>{description}</p>
                    </div>
                  );
                })}
            </Col>
          </div>
        ) : (
          <Col xs={12}>
            <Swiper slidesPerView={1} spaceBetween={16} navigation={isMobile}>
              {steps &&
                steps.map(({ title, description }, i) => {
                  return (
                    <SwiperSlide key={i} className={"Method-slide"}>
                      <img src={stepImage[i]} alt="" className="illustration" />
                      <img src={stepNumber[i]} alt="" className="number" />
                      <h3>{title}</h3>
                      <p>{description}</p>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default Method;
