import "./Services.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import React from "react";
import SectionTitle from "../../partials/SectionTitle/SectionTitle.js";

const Services = ({ highlighted, title, rightCol, leftCol }) => {
  return (
    <section className="Services">
      <Row>
        <SectionTitle text={title} highlight={highlighted} />
        <Col
          xs={12}
          md={4}
          mdOffset={3}
          className="Services-colLeft with-arrow"
        >
          {leftCol.paragraph.map(({ title, content }, i) => (
            <div key={i} className="Services-block">
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
          ))}
        </Col>
        <Col xs={12} md={4} className="Services-colRight">
          {rightCol.paragraph.map(({ title, content }, i) => (
            <div key={i} className="Services-block">
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
          ))}
        </Col>
      </Row>
    </section>
  );
};

export default Services;
