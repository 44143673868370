import "./Story.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import React from "react";
import SquareIcon from "../../../img/decoration/red-half-square.svg";
import SquareIcon2 from "../../../img/decoration/red-half-square-2.svg";

const Story = ({ paragrapheOne, paragrapheThree, paragrapheTwo }) => {
  return (
    <section className="Story">
      <Row>
        <Col xs={12} className="Story-year">
          2010
        </Col>
        <Col xs={12} md={6} mdOffset={1} className="Story-paragraph">
          {paragrapheOne}
        </Col>
        <Col md={5} className="Story-deco">
          <img className="first" src={SquareIcon2} alt="" />
        </Col>
        <Col md={5} className="Story-deco">
          <img src={SquareIcon} alt="" />
        </Col>
        <Col xs={12} md={6} className="Story-paragraph">
          {paragrapheTwo}
        </Col>
        <Col xs={12} md={6} mdOffset={1} className="Story-paragraph">
          {paragrapheThree}
        </Col>
      </Row>
    </section>
  );
};

export default Story;
