import "./Us.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";
import { useWindowSize } from "../../../helpers/helpers.js";

const Us = ({ title, description, imageList }) => {
  const { isMobile } = useWindowSize();

  return (
    <section className="Us">
      <Row>
        <Col xs={12} md={7} mdOffset={1}>
          <h1>{title}</h1>
        </Col>
        <Col xs={12} md={8} mdOffset={2} className={"with-frame"}>
          <p>{description}</p>
        </Col>
      </Row>
      <Row className="images" reverse={!isMobile}>
        <Col xs={12} md={6} className="big-image">
          <PreviewCompatibleImage imageInfo={imageList[1].image} />
        </Col>
        <Col xs={4} className="image-circle" />
        <Col xs={8} xsOffset={4} md={4} mdOffset={2} className="small-image">
          <PreviewCompatibleImage imageInfo={imageList[0].image} />
        </Col>
      </Row>
    </section>
  );
};

export default Us;
